import axios from '@/utils/axios'

// 预存货列表
export const prestockList = data => axios({
    method: 'post',
    url: '/crm/customer/prestockList',
    data
})
  
// 出货
export const shipment= data => axios({
    method: 'post',
    url: '/crm/customerPrestock/shipment',
    data
})
//商品表出货
export const goodsDetailList= data => axios({
    method: 'post',
    url: '/crm/customerPrestock/prestockGoodslist',
    data
})

//出货单-出货-单商品出库
export const prostockShipmentVerify= data => axios({
    method: 'post',
    url: '/crm/customerPrestock/prostockShipmentVerify',
    data
})

//门店预存货列表
export const shopPrestockList= data => axios({
    method: 'post',
    url: '/crm/customerPrestock/shopPrestockList',
    data
})

//门店预存商品列表
export const shopPrestockGoodsList = data => axios({
    method: 'post',
    url: '/crm/customerPrestock/shopPrestockGoodsList',
    data
})
//门店预存商品列表-明细
export const shopPrestockGoodsDateil = data => axios({
    method: 'post',
    url: '/crm/customerPrestock/shopPrestockGoodsDateil',
    data
})

//门店预存商品列表-退出存货-明细
export const refundOutPrestockDateil = data => axios({
    method: 'post',
    url: '/crm/customerPrestock/refundOutPrestockDateil',
    data
})

// 商品预存记录导出
export const preLogExport = data =>
axios.post('/crm/customerPrestock/preLogExport', data)
    .then(res => res)

// 预存商品导出
export const preGoodsExport = data =>
    axios.post('/crm/customerPrestock/preGoodsExport', data)
        .then(res => res)

// 顾客预存记录导出
export const preListExit = data =>
    axios.post('/crm/customerPrestock/preListExit', data)
        .then(res => res)
    






